export const palette: any = {
  material: {
    type: "light",
    primary: {
      main: "#005640",
    },
    secondary: {
      main: "#000000",
    },
    background: {
      paper: "#FFFFFF",
      default: "#fafafa",
    },
  },
  generic: [""],
};
